.page {
  margin: 1rem;
}

table {
  border: none;
}

body {
  background-image: url("bg.c8eaaf2c.jpg");
  font-family: sans-serif;
}

table.graga {
  border-collapse: collapse;
  text-align: center;
}

#canvas {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
}

.content {
  z-index: 1;
}

.graga th {
  background-image: linear-gradient(#5bdfac, #11e7c4);
}

.graga td {
  padding: 1rem;
}

.graga {
  width: 20rem;
  float: right;
  box-shadow: -2px 2px 3px #0000002f;
}

.box-head {
  text-align: center;
  background-image: linear-gradient(#5bdfac, #11e7c4);
  border-radius: 10px 0 0;
  margin: 0;
  padding: .4rem;
}

.preben {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.box {
  width: 20rem;
  flex: 0 auto;
  margin: 1rem .5rem;
  box-shadow: 2px 2px 3px #0000002f;
}

tbody {
  background-color: #ffebcd;
}

.box#owo {
  width: auto;
  margin-left: auto !important;
}

.clik {
  width: 100%;
  color: #f1f1f1;
  background-image: linear-gradient(-45deg, #e100ff, #00fbff);
  background-size: 400% 400%;
  border: none;
  padding: 3rem;
  animation: 15s infinite clicky-clack;
}

@keyframes clicky-clack {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

#upgrades {
  width: 10rem !important;
}

#status table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

#status th, td {
  padding: .2rem 1rem;
}

footer {
  height: 35px;
  color: #000;
  text-align: right;
  background: #ffebcd;
  border-radius: 5px;
  padding: 10px 30px;
  position: absolute;
  bottom: 0;
  right: 50px;
  box-shadow: 2px 2px 3px #0000002f;
}

footer .menu {
  border-bottom: 1px dotted #b9b9b9;
  padding: 5px;
  display: flex;
}

footer .menu div {
  margin-right: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: 20s linear infinite App-logo-spin;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=index.1ac47503.css.map */
