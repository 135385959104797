.page {
    margin: 1rem;
}

table {
    border: none;
}

body {
    font-family: sans-serif;
    background-image: url("../img/bg.jpg");
}

table.graga {
    /* border: 2px solid black; */
    border-collapse: collapse;
    text-align: center;
}

#canvas {
    position:fixed; top:0;left:0;
    z-index: -1;
}

.content {
    z-index: 1;
}

.graga th {
    background-image: linear-gradient(#5bdfac, #11e7c4);
    /* border: 2px solid black; */
}

.graga td {
    padding: 1rem;
    /* border: 2px solid black; */
}

.graga {
    width: 20rem;
    float: right;
    box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.185);
}

.box-head {
    /* border-bottom: solid 1px #0000002c; */
    text-align: center;
    background-image: linear-gradient(#5bdfac, #11e7c4);
    padding: 0.4rem;
    margin: 0;
    border-radius: 10px 0 0;
}

.preben {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.box {
    flex: 0 1 auto;
    width: 20rem;
    margin: 1rem 0.5rem;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.185);
}

tbody {
    background-color: blanchedalmond;
}

.box#owo {
    margin-left: auto !important;
    width: auto;
}

.clik {
    padding: 3rem;
    width: 100%;
    color: rgb(241, 241, 241);
    background-image: linear-gradient(-45deg, #e100ff, #00fbff);
    background-size: 400% 400%;
    border: none;
    animation: clicky-clack 15s ease infinite;
}

@keyframes clicky-clack {
    0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

#upgrades {
    width: 10rem !important;
}

#status table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
}

#status th,
td {
    /* border: 2px solid black; */
    padding: 0.2rem 1rem;
}

footer {
    position: absolute;
    bottom: 0px;
    right: 50px;
    height: 35px;
    color: #000;
    background: blanchedalmond;
    border-radius: 5px;
    text-align: right;
    padding: 10px 30px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.185);
}

footer .menu {
    display: flex;
    padding: 5px;
    border-bottom: 1px dotted #b9b9b9;
}

footer .menu div {
    margin-right: 10px;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}